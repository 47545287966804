<template>
  <div class="tendency-wrap">
    <div class="tendency-search">
      <input
        type="text"
        v-model="search"
        @keyup.enter="searchHandle"
        placeholder="请输入品牌名或关键词搜索"
      />
      <span
        class="pointer"
        @click="searchHandle"
      ><i class="icon-search"></i> 搜索</span>
    </div>
    <div class="tendency-content">
      <div class="tendency-left">
        <div class="tendency-select">
          <span
            :class="[type==='Tendency'?'active': '']"
            @click="selectLabel('Tendency')"
          >商业洞察</span>
          <span
            :class="[type==='Inventory'?'active': '']"
            @click="selectLabel('Inventory')"
          >品牌盘点</span>
        </div>
        <div class="tendency-article">
          <news-item
            v-for="item in newsList"
            :key="item.newsId"
            :detail="item"
            :title="type =='Tendency' ? '商业洞察列表' : '品牌盘点'"
          ></news-item>
        </div>
        <div class="w-page-sty">
          <el-pagination
            background
            v-if="total>12"
            layout="prev, pager, next"
            :current-page="pageNum"
            @current-change="pageHandle"
            :page-size="pageSize"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div class="tendency-right">
        <hot-focus class="mb-20"></hot-focus>
        <brand-top class="mb-20"></brand-top>
        <brand-tab></brand-tab>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import brandTop from './brandTop.vue'
import newsItem from './newsItem.vue'
import brandTab from './brandTab'
import hotFocus from './hotFocus'
export default {
  data () {
    return {
      search: '',
      type: 'Tendency',
      newsList: [],
      pageNum: 1,
      pageSize: 12,
      total: 0
    }
  },
  components: {
    brandTop,
    newsItem,
    brandTab,
    hotFocus
  },
  created () {
    this.type = this.$route.query.type || 'Tendency'
    this.getList()
  },
  methods: {
    pageHandle (val) {
      this.pageNum = val
      this.getList(true)
    },
    getList (flag) {
      this.loading = true
      this.axios.post(api.getNewsBrandNewsSearch, {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        category: this.type === 'Tendency' ? '12' : '6303',
        keyWord: ''
      })
        .then((res) => {
          this.loading = false
          if (res.data.code === 0) {
            if (flag) {
              document.documentElement.scrollTop = 0
            }
            this.newsList = res.data.data.newsListVos
            this.total = res.data.data.newsAmount
          }
        })
    },
    selectLabel (type) {
      this.pageNum = 1
      this.type = type
      this.getList()
    },
    // 搜索
    searchHandle () {
      if (!this.search) {
        this.$message({
          type: 'warning',
          message: '请输入你要搜索的关键词！',
          duration: 3000
        })
      } else {
        const page = this.$router.resolve({
          path: '/brandTendencySearch',
          query: {
            search: this.search
          }
        })
        window.open('/' + page.href, '_blank')
      }
    },
    //
    toDetail () {
      const brandTendencyDetail = this.$router.resolve({
        path: '/brandTendencyDetail',
        query: {
          title: this.type === 'Tendency' ? '商业洞察列表' : '品牌盘点'
        }
      })
      window.open('/' + brandTendencyDetail.href, '_blank')
    },
    // 更多资讯
    toList () {
      const page = this.$router.resolve({
        path: '/brandTendencyList',
        query: {
        }
      })
      window.open('/' + page.href, '_blank')
    }
  }
}
</script>

<style lang="stylus" scoped>
.mb-20
  margin-bottom 20px
.tendency-wrap
  margin 0 auto
  width 1280px
  color #ffffff
.tendency-content
  display flex
  .tendency-left
    width 808px
    margin-right 23px
  .tendency-right
    width 450px
.tendency-search
  margin-top 25px
  margin-bottom 25px
  display flex
  input
    line-height 40px
    height 40px
    background rgba(39, 41, 48, 1)
    border 1px solid transparent
    outline none
    color #ffffff
    font-size 14px
    padding 0 21px
    flex 1
  span
    color #ffffff
    font-size 14px
    width 113px
    background rgba(62, 62, 69, 1)
    display inline-block
    line-height 40px
    height 40px
    text-align center
    i
      font-size 17px
      margin-right 6px
      margin-left -18px
      vertical-align middle
      color #ffffff
      &:before
        content '\E741'
        color #ffffff
.tendency-article
  background-color #272930
  border-radius 2px
.tendency-title
  position relative
  font-size 18px
  color #fff
  font-weight 500
  margin-left 20px
  padding-left 10px
  margin-bottom 20px
  i
    position absolute
    left 0
    top 3px
    width 2px
    height 18px
    background #ffa134
.see-more
  font-size 15px
  line-height 60px
  text-align center
  color #999
  span
    cursor pointer
.tendency-select
  border-bottom 1px solid #424242
  background-color #272930
  padding 0 30px
  span
    height 58px
    line-height 58px
    display inline-block
    text-align center
    border-bottom 4px solid transparent
    margin-right 70px
    cursor pointer
    &.active
      border-bottom 4px solid #FFA134
    &:hover
      border-bottom 4px solid #FFA134
.w-page-sty
  margin-bottom 20px
  text-align center
</style>
